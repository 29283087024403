/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenAbcPageAction
 */
export interface OpenAbcPageAction {
    /**
     * 
     * @type {string}
     * @memberof OpenAbcPageAction
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof OpenAbcPageAction
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof OpenAbcPageAction
     */
    title: string;
}

/**
 * Check if a given object implements the OpenAbcPageAction interface.
 */
export function instanceOfOpenAbcPageAction(value: object): value is OpenAbcPageAction {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function OpenAbcPageActionFromJSON(json: any): OpenAbcPageAction {
    return OpenAbcPageActionFromJSONTyped(json, false);
}

export function OpenAbcPageActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenAbcPageAction {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'url': json['url'],
        'title': json['title'],
    };
}

export function OpenAbcPageActionToJSON(value?: OpenAbcPageAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'url': value['url'],
        'title': value['title'],
    };
}

