/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CallPhoneNumberAction
 */
export interface CallPhoneNumberAction {
    /**
     * 
     * @type {string}
     * @memberof CallPhoneNumberAction
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CallPhoneNumberAction
     */
    phoneNumber: string;
}

/**
 * Check if a given object implements the CallPhoneNumberAction interface.
 */
export function instanceOfCallPhoneNumberAction(value: object): value is CallPhoneNumberAction {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    return true;
}

export function CallPhoneNumberActionFromJSON(json: any): CallPhoneNumberAction {
    return CallPhoneNumberActionFromJSONTyped(json, false);
}

export function CallPhoneNumberActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallPhoneNumberAction {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function CallPhoneNumberActionToJSON(value?: CallPhoneNumberAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'phoneNumber': value['phoneNumber'],
    };
}

