export default function plural(
  parts: TemplateStringsArray,
  count: number
) {
  const [before, after] = parts;
  
  const toSingular = (str: string) => str.replaceAll(/\((s|x)\)/g, "");
  const toPlural = (str: string) => str.replaceAll(/\((s|x)\)/g, "$1");

  switch (count) {
    case 0:
    case 1:
      return [toSingular(before), count, toSingular(after)].join("");

    default:
      return [toPlural(before), count, toPlural(after)].join("");
  }
}
