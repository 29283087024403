/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MessageDto } from './MessageDto';
import {
    MessageDtoFromJSON,
    MessageDtoFromJSONTyped,
    MessageDtoToJSON,
} from './MessageDto';

/**
 * 
 * @export
 * @interface GetMessagesResponse
 */
export interface GetMessagesResponse {
    /**
     * 
     * @type {number}
     * @memberof GetMessagesResponse
     */
    selfId: number;
    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof GetMessagesResponse
     */
    messages: Array<MessageDto>;
}

/**
 * Check if a given object implements the GetMessagesResponse interface.
 */
export function instanceOfGetMessagesResponse(value: object): value is GetMessagesResponse {
    if (!('selfId' in value) || value['selfId'] === undefined) return false;
    if (!('messages' in value) || value['messages'] === undefined) return false;
    return true;
}

export function GetMessagesResponseFromJSON(json: any): GetMessagesResponse {
    return GetMessagesResponseFromJSONTyped(json, false);
}

export function GetMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMessagesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'selfId': json['selfId'],
        'messages': ((json['messages'] as Array<any>).map(MessageDtoFromJSON)),
    };
}

export function GetMessagesResponseToJSON(value?: GetMessagesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selfId': value['selfId'],
        'messages': ((value['messages'] as Array<any>).map(MessageDtoToJSON)),
    };
}

