import { createContext, useContext } from "react";

export interface AuthenticatedUser {
  readonly accessToken: string;
  readonly displayName: string;
}

export interface AuthenticationService {
  getUser(): AuthenticatedUser | undefined;
  setUser(user: AuthenticatedUser | undefined): void;
}

export class InMemoryAuthenticationService implements AuthenticationService {
  private user: AuthenticatedUser | undefined = undefined;

  getUser() {
    return this.user;
  }

  setUser(user: AuthenticatedUser | undefined): void {
    this.user = user;
  }
}

export class StorageAuthenticationService implements AuthenticationService {
  constructor(
    private readonly storage: Storage,
    private readonly key = "user"
  ) {}

  getUser() {
    const { storage, key } = this;

    try {
      const json = storage.getItem(key);
      if (json) {
        return JSON.parse(json);
      }
    } catch (e) {
      storage.removeItem(key);
    }

    return undefined;
  }

  setUser(user: AuthenticatedUser | undefined): void {
    const { storage, key } = this;

    if (user) {
      storage.setItem(key, JSON.stringify(user));
    } else {
      storage.removeItem(key);
    }
  }
}

const AuthenticationContext = createContext<AuthenticationService>(
  new InMemoryAuthenticationService()
);
AuthenticationContext.displayName = "AuthenticationContext";

export const AuthenticationProvider = AuthenticationContext.Provider;

export const useAuthenticationService = () => useContext(AuthenticationContext);
