/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConversationDto } from './ConversationDto';
import {
    ConversationDtoFromJSON,
    ConversationDtoFromJSONTyped,
    ConversationDtoToJSON,
} from './ConversationDto';

/**
 * 
 * @export
 * @interface GetConversationsResponse
 */
export interface GetConversationsResponse {
    /**
     * 
     * @type {Array<ConversationDto>}
     * @memberof GetConversationsResponse
     */
    conversations: Array<ConversationDto>;
}

/**
 * Check if a given object implements the GetConversationsResponse interface.
 */
export function instanceOfGetConversationsResponse(value: object): value is GetConversationsResponse {
    if (!('conversations' in value) || value['conversations'] === undefined) return false;
    return true;
}

export function GetConversationsResponseFromJSON(json: any): GetConversationsResponse {
    return GetConversationsResponseFromJSONTyped(json, false);
}

export function GetConversationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConversationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'conversations': ((json['conversations'] as Array<any>).map(ConversationDtoFromJSON)),
    };
}

export function GetConversationsResponseToJSON(value?: GetConversationsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conversations': ((value['conversations'] as Array<any>).map(ConversationDtoToJSON)),
    };
}

