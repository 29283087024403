import { Box, Typography, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AbcUsersAutocomplete, {
  AbcAccountOption,
} from "../../components/AbcUsersAutocomplete";

import SendIcon from "@mui/icons-material/Send";
import { useConversationsApi } from "../../lib/api-clients";
import { useAlertService } from "../../lib/alerts";

export function GroupMessage() {
  const alerts = useAlertService();
  const conversationsApi = useConversationsApi();

  const [recipients, setRecipients] = useState<AbcAccountOption[]>([]);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    function warnBeforeLeaving(e: BeforeUnloadEvent) {
      if (message || recipients.length > 0) {
        e.preventDefault();
      }
    }

    window.addEventListener("beforeunload", warnBeforeLeaving);
    return () => window.removeEventListener("beforeunload", warnBeforeLeaving);
  });

  const handleSend = async () => {
    setSending(true);

    try {
      await conversationsApi.postMessageToGroup({
        content: message,
        recipientUserAccountIds: recipients.map((acc) => acc.id),
      });

      alerts.success("Le message a été envoyé.");

      setRecipients([]);
      setMessage("");
    } catch (e) {
      alerts.error("Erreur lors de l’envoi du message. Veuillez réessayer.");
      console.error("Could not send message", e);
    } finally {
      setSending(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 2,
      }}
    >
      <Typography component="strong" variant="h6">
        Écrire un nouveau message
      </Typography>
      <AbcUsersAutocomplete
        label="Destinataires"
        value={recipients}
        onChange={setRecipients}
      />
      <TextField
        id="message"
        type="message"
        label="Contenu du message"
        placeholder="Votre message..."
        variant="outlined"
        fullWidth
        multiline
        value={message}
        rows={10}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end " }}
      >
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSend}
          disabled={!message || sending}
        >
          Envoyer
        </Button>
      </Box>
    </Box>
  );
}
