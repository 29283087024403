import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSecurityApi } from "../../lib/api-clients";
import { useAlertService } from "../../lib/alerts";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { isPast, parseISO } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const alerts = useAlertService();
  const securityApi = useSecurityApi();

  const [email, setEmail] = useState("");
  const [key, setKey] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [signature, setSignature] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [sending, setSending] = useState(false);

  const isLongEnough = password.trim().length >= 8;
  const hasLowercaseLetter = /[a-z]/.test(password);
  const hasUppercaseLetter = /[A-Z]/.test(password);
  const hasDigit = /[0-9]/.test(password);

  const isPasswordValid =
    isLongEnough && hasLowercaseLetter && hasUppercaseLetter && hasDigit;
  const isPassword2Valid = password2.trim() === password.trim();

  const disabled = sending || !isPasswordValid || !isPassword2Valid;

  useEffect(() => {
    const email = params.get("email");
    const key = params.get("key");
    const expiresAt = params.get("expiresAt");
    const signature = params.get("signature");

    if (!email || !key || !expiresAt || !signature) {
      alerts.error("Le lien est invalide. Veuillez renouveler votre demande.");
      navigate("/login");
      return;
    }

    if (isPast(parseISO(expiresAt))) {
      alerts.error("Le lien a expiré. Veuillez renouveler votre demande.");
      navigate("/login");
      return;
    }

    setEmail(email);
    setKey(key);
    setExpiresAt(expiresAt);
    setSignature(signature);
  }, [params, alerts, navigate]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSending(true);
    try {
      await securityApi.resetPassword({
        email,
        key,
        expiresAt: parseISO(expiresAt),
        signature,
        newPassword: password,
      });
      alerts.success("Le mot de passe a été modifié.");
      navigate("/login");
    } catch (e) {
      alerts.error(
        "Erreur lors de la modification du mot de passe. Veuillez réessayer."
      );
      console.error("Could not update password", e);
    } finally {
      setSending(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="username"
            name="username"
            autoComplete="username"
            value={email}
            hidden
          />
          <Stack spacing={4} sx={{ padding: 8 }}>
            <Box>
              <Button
                startIcon={<ArrowBackIcon />}
                component={Link}
                to="/login"
              >
                Retour
              </Button>
            </Box>
            <Typography variant="h4" component="h1">
              Nouveau mot de passe
            </Typography>

            <TextField
              label="Adresse email"
              variant="standard"
              value={email}
              required
              disabled
            />

            <TextField
              label="Nouveau mot de passe"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              autoComplete="new-password"
              variant="standard"
              value={password}
              error={password.length > 0 && !isPasswordValid}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <TextField
              label="Confirmer le mot de passe"
              type={showPassword ? "text" : "password"}
              id="password2"
              name="password"
              autoComplete="new-password"
              variant="standard"
              value={password2}
              error={password2.length > 0 && !isPassword2Valid}
              onChange={(e) => setPassword2(e.target.value)}
              required
            />

            <Typography component="div">
              Votre mot de passe doit contenir&nbsp;:
              <ul>
                <li>au moins 8 caractères {isLongEnough && "✅"}</li>
                <li>une lettre minuscule {hasLowercaseLetter && "✅"}</li>
                <li>une lettre majuscule {hasUppercaseLetter && "✅"}</li>
                <li>un chiffre {hasDigit && "✅"}</li>
              </ul>
              <strong>
                Privilégiez l’utilisation du gestionnaire de mots de passe pour
                créer et sauvegarder vos identifiants.
              </strong>
            </Typography>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch onChange={(_, checked) => setShowPassword(checked)} />
                }
                label="Afficher le mot de passe"
              />
            </FormGroup>

            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={disabled}
            >
              Modifier
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
