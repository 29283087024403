/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThirdPartyCredentials } from './ThirdPartyCredentials';
import {
    ThirdPartyCredentialsFromJSON,
    ThirdPartyCredentialsFromJSONTyped,
    ThirdPartyCredentialsToJSON,
} from './ThirdPartyCredentials';

/**
 * 
 * @export
 * @interface AbcLoginResponse
 */
export interface AbcLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof AbcLoginResponse
     */
    accessToken: string;
    /**
     * 
     * @type {ThirdPartyCredentials}
     * @memberof AbcLoginResponse
     */
    credentials: ThirdPartyCredentials;
}

/**
 * Check if a given object implements the AbcLoginResponse interface.
 */
export function instanceOfAbcLoginResponse(value: object): value is AbcLoginResponse {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('credentials' in value) || value['credentials'] === undefined) return false;
    return true;
}

export function AbcLoginResponseFromJSON(json: any): AbcLoginResponse {
    return AbcLoginResponseFromJSONTyped(json, false);
}

export function AbcLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbcLoginResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'credentials': ThirdPartyCredentialsFromJSON(json['credentials']),
    };
}

export function AbcLoginResponseToJSON(value?: AbcLoginResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessToken': value['accessToken'],
        'credentials': ThirdPartyCredentialsToJSON(value['credentials']),
    };
}

