import "./globals.css";

import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { frFR as locale } from "@mui/material/locale";
import { frFR as dataGridLocale } from "@mui/x-data-grid/locales";

import { RouterProvider } from "react-router-dom";
import {
  AuthenticationProvider,
  StorageAuthenticationService,
} from "./lib/authentication";
import { Alert, createTheme, Snackbar, ThemeProvider } from "@mui/material";
import { AlertProps } from "@mui/material";
import { AlertServiceProvider, AlertService } from "./lib/alerts";
import { router } from "./router";

interface SnackbarAlert {
  severity: AlertProps["severity"];
  message: string;
}

const theme = createTheme({}, locale, dataGridLocale);

const App = () => {
  const [alert, setAlert] = useState<SnackbarAlert | undefined>(undefined);

  const alertService = useMemo(() => {
    return new (class SnackbarAlertService implements AlertService {
      success(message: string): void {
        setAlert({ severity: "success", message });
      }
      info(message: string): void {
        setAlert({ severity: "info", message });
      }
      warn(message: string): void {
        setAlert({ severity: "warning", message });
      }
      error(message: string): void {
        setAlert({ severity: "error", message });
      }
    })();
  }, [setAlert]);

  const authenticationService = new StorageAuthenticationService(localStorage);

  const handleDismissAlert = () => setAlert(undefined);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertServiceProvider value={alertService}>
          <AuthenticationProvider value={authenticationService}>
            <RouterProvider router={router} />
          </AuthenticationProvider>
        </AlertServiceProvider>
        {alert && (
          <Snackbar
            open={true}
            autoHideDuration={8000}
            onClose={handleDismissAlert}
          >
            <Alert
              onClose={handleDismissAlert}
              severity={alert.severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
