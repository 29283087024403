/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminLoginResponse
 */
export interface AdminLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof AdminLoginResponse
     */
    accessToken: string;
}

/**
 * Check if a given object implements the AdminLoginResponse interface.
 */
export function instanceOfAdminLoginResponse(value: object): value is AdminLoginResponse {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    return true;
}

export function AdminLoginResponseFromJSON(json: any): AdminLoginResponse {
    return AdminLoginResponseFromJSONTyped(json, false);
}

export function AdminLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminLoginResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
    };
}

export function AdminLoginResponseToJSON(value?: AdminLoginResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessToken': value['accessToken'],
    };
}

