import { useEffect, useState } from "react";
import { Badge, Box, Button, TableContainer, Typography } from "@mui/material";

import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ForumIcon from "@mui/icons-material/Forum";
import EditIcon from "@mui/icons-material/Edit";
import plural from "../../lib/plural";
import merge from "../../lib/merge";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import FriendlyDateTime from "../../components/FriendlyDateTime";
import { Link, useNavigate } from "react-router-dom";
import { ConversationDto } from "../../api-client";
import { useConversationsApi } from "../../lib/api-clients";
import { useAlertService } from "../../lib/alerts";

const ConversationBadge = (props: {
  conversation: ConversationDto;
}): JSX.Element => {
  const {
    conversation: { numberOfAdminUnreadMessages, numberOfOwnerUnreadMessages },
  } = props;

  if (numberOfAdminUnreadMessages > 0) {
    return (
      <Badge color="primary" badgeContent={numberOfAdminUnreadMessages}>
        <ChatBubbleIcon color="action" />
      </Badge>
    );
  }

  if (numberOfOwnerUnreadMessages > 0) {
    return (
      <Badge
        color="primary"
        variant="dot"
        badgeContent={numberOfOwnerUnreadMessages}
      >
        <ForumIcon color="action" />
      </Badge>
    );
  }

  return <MarkChatReadIcon color="disabled" />;
};

const ConversationStatus = (props: {
  conversation: ConversationDto;
}): JSX.Element => {
  const {
    conversation: { numberOfAdminUnreadMessages, numberOfOwnerUnreadMessages },
  } = props;

  if (numberOfAdminUnreadMessages > 0) {
    return (
      <strong>{plural`${numberOfAdminUnreadMessages} nouveau(x) message(s)`}</strong>
    );
  }
  if (numberOfOwnerUnreadMessages > 0) {
    return <em>Réponse envoyée</em>;
  }
  return <>À jour</>;
};

const conversationStatus = (conversation: ConversationDto): string => {
  const { numberOfAdminUnreadMessages, numberOfOwnerUnreadMessages } =
    conversation;

  if (numberOfAdminUnreadMessages > 0) {
    return "new-messages";
  }
  if (numberOfOwnerUnreadMessages > 0) {
    return "answered";
  }
  return "up-to-date";
};

const columns: GridColDef<ConversationDto>[] = [
  {
    field: "badge",
    headerName: "",
    width: 50,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ConversationDto>) => (
      <ConversationBadge conversation={params.row} />
    ),
  },
  {
    field: "displayName",
    headerName: "Destinataire",
    width: 200,
    valueGetter: (_, conversation) => conversation.owner.displayName,
  },
  {
    field: "lastMessageDate",
    type: "date",
    headerName: "Dernier message",
    width: 150,
    align: "right",
    headerAlign: "right",
    valueGetter: (_, conversation) => conversation.lastMessageDate,
    renderCell: (params: GridRenderCellParams<ConversationDto>) => (
      <FriendlyDateTime date={params.row.lastMessageDate} />
    ),
  },
  {
    field: "status",
    type: "singleSelect",
    headerName: "Statut",
    width: 200,
    valueOptions: [
      { value: "new-messages", label: "Nouveau(x) messages(s)" },
      { value: "answered", label: "Réponse envoyée" },
      { value: "up-to-date", label: "À jour" },
    ],
    valueGetter: (_, conversation) => conversationStatus(conversation),
    renderCell: (params: GridRenderCellParams<ConversationDto>) => (
      <ConversationStatus conversation={params.row} />
    ),
  },
];

export function ConversationTable() {
  const navigate = useNavigate();
  const alerts = useAlertService();
  const conversationsApi = useConversationsApi();

  const [conversations, setConversations] = useState<ConversationDto[]>([]);

  useEffect(() => {
    async function fetchConversations(since?: Date) {
      try {
        const response = await conversationsApi.getConversations(since);
        setConversations((initial) => merge(initial, response.conversations));
      } catch (error) {
        alerts.error(
          "Erreur lors du chargement des conversations. Veuillez réessayer."
        );
        console.error("Error fetching data:", error);
      }
    }

    let since = new Date();
    fetchConversations();

    const intervalId = setInterval(() => {
      const now = new Date();
      fetchConversations(since);
      since = now;
    }, 2000);
    return () => clearInterval(intervalId);
  }, [conversationsApi, alerts]);

  const handleRowClick = (params: GridRowParams<ConversationDto>): void => {
    navigate(`/conversations/${params.row.id}`);
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box padding={2}>
        <Typography component="strong" variant="h6">
          Conversations
        </Typography>
      </Box>
      <TableContainer sx={{ flex: 1 }}>
        <DataGrid
          rows={conversations}
          columns={columns}
          onRowClick={handleRowClick}
          initialState={{
            sorting: {
              sortModel: [{ field: "lastMessageDate", sort: "desc" }],
            },
          }}
          sx={{ marginLeft: 2, marginRight: 2 }}
        />
      </TableContainer>
      <Box sx={{ padding: 2, display: "flex", justifyContent: "end" }}>
        <Button
          color="primary"
          size="medium"
          component={Link}
          to="/conversations/nouveau-message"
          startIcon={<EditIcon />}
        >
          Écrire un message
        </Button>
      </Box>
    </Box>
  );
}
