/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConversationDto } from './ConversationDto';
import {
    ConversationDtoFromJSON,
    ConversationDtoFromJSONTyped,
    ConversationDtoToJSON,
} from './ConversationDto';

/**
 * 
 * @export
 * @interface GetConversationResponse
 */
export interface GetConversationResponse {
    /**
     * 
     * @type {ConversationDto}
     * @memberof GetConversationResponse
     */
    conversation: ConversationDto;
}

/**
 * Check if a given object implements the GetConversationResponse interface.
 */
export function instanceOfGetConversationResponse(value: object): value is GetConversationResponse {
    if (!('conversation' in value) || value['conversation'] === undefined) return false;
    return true;
}

export function GetConversationResponseFromJSON(json: any): GetConversationResponse {
    return GetConversationResponseFromJSONTyped(json, false);
}

export function GetConversationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConversationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'conversation': ConversationDtoFromJSON(json['conversation']),
    };
}

export function GetConversationResponseToJSON(value?: GetConversationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conversation': ConversationDtoToJSON(value['conversation']),
    };
}

