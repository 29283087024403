/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetAction } from './WidgetAction';
import {
    WidgetActionFromJSON,
    WidgetActionFromJSONTyped,
    WidgetActionToJSON,
} from './WidgetAction';

/**
 * 
 * @export
 * @interface Widget
 */
export interface Widget {
    /**
     * 
     * @type {WidgetAction}
     * @memberof Widget
     */
    action: WidgetAction;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    icon?: string;
}

/**
 * Check if a given object implements the Widget interface.
 */
export function instanceOfWidget(value: object): value is Widget {
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function WidgetFromJSON(json: any): Widget {
    return WidgetFromJSONTyped(json, false);
}

export function WidgetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Widget {
    if (json == null) {
        return json;
    }
    return {
        
        'action': WidgetActionFromJSON(json['action']),
        'label': json['label'],
        'icon': json['icon'] == null ? undefined : json['icon'],
    };
}

export function WidgetToJSON(value?: Widget | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': WidgetActionToJSON(value['action']),
        'label': value['label'],
        'icon': value['icon'],
    };
}

