/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Widget } from './Widget';
import {
    WidgetFromJSON,
    WidgetFromJSONTyped,
    WidgetToJSON,
} from './Widget';

/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    title?: string;
    /**
     * 
     * @type {Array<Widget>}
     * @memberof Group
     */
    widgets: Array<Widget>;
}

/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): value is Group {
    if (!('widgets' in value) || value['widgets'] === undefined) return false;
    return true;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'widgets': ((json['widgets'] as Array<any>).map(WidgetFromJSON)),
    };
}

export function GroupToJSON(value?: Group | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'widgets': ((value['widgets'] as Array<any>).map(WidgetToJSON)),
    };
}

