import { Box, Paper } from "@mui/material";
import { UserAccountsTable } from "./UserAccountsTable";
import { Outlet } from "react-router-dom";

export function UserAccountsPage() {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        gap: 2,
        padding: 2,
        overflow: "hidden",
      }}
    >
      <Paper sx={{ flex: 1 }}>
        <UserAccountsTable />
      </Paper>
      <Outlet />
    </Box>
  );
}
