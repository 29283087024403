/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAdminAccountRequest,
  CreateAdminAccountResponse,
  GetAccountsResponse,
} from '../models/index';
import {
    CreateAdminAccountRequestFromJSON,
    CreateAdminAccountRequestToJSON,
    CreateAdminAccountResponseFromJSON,
    CreateAdminAccountResponseToJSON,
    GetAccountsResponseFromJSON,
    GetAccountsResponseToJSON,
} from '../models/index';

export interface CreateAdminAccountOperationRequest {
    createAdminAccountRequest: CreateAdminAccountRequest;
}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     */
    async createAdminAccountRaw(requestParameters: CreateAdminAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAdminAccountResponse>> {
        if (requestParameters['createAdminAccountRequest'] == null) {
            throw new runtime.RequiredError(
                'createAdminAccountRequest',
                'Required parameter "createAdminAccountRequest" was null or undefined when calling createAdminAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAdminAccountRequestToJSON(requestParameters['createAdminAccountRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAdminAccountResponseFromJSON(jsonValue));
    }

    /**
     */
    async createAdminAccount(createAdminAccountRequest: CreateAdminAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAdminAccountResponse> {
        const response = await this.createAdminAccountRaw({ createAdminAccountRequest: createAdminAccountRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAccountsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAccountsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAccountsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAccounts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAccountsResponse> {
        const response = await this.getAccountsRaw(initOverrides);
        return await response.value();
    }

}
