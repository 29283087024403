/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountDto } from './AccountDto';
import {
    AccountDtoFromJSON,
    AccountDtoFromJSONTyped,
    AccountDtoToJSON,
} from './AccountDto';

/**
 * 
 * @export
 * @interface CreateAdminAccountResponse
 */
export interface CreateAdminAccountResponse {
    /**
     * 
     * @type {AccountDto}
     * @memberof CreateAdminAccountResponse
     */
    account: AccountDto;
}

/**
 * Check if a given object implements the CreateAdminAccountResponse interface.
 */
export function instanceOfCreateAdminAccountResponse(value: object): value is CreateAdminAccountResponse {
    if (!('account' in value) || value['account'] === undefined) return false;
    return true;
}

export function CreateAdminAccountResponseFromJSON(json: any): CreateAdminAccountResponse {
    return CreateAdminAccountResponseFromJSONTyped(json, false);
}

export function CreateAdminAccountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAdminAccountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'account': AccountDtoFromJSON(json['account']),
    };
}

export function CreateAdminAccountResponseToJSON(value?: CreateAdminAccountResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'account': AccountDtoToJSON(value['account']),
    };
}

