/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AbcAccountDto } from './AbcAccountDto';
import {
    AbcAccountDtoFromJSON,
    AbcAccountDtoFromJSONTyped,
    AbcAccountDtoToJSON,
} from './AbcAccountDto';
import type { AdminAccountDto } from './AdminAccountDto';
import {
    AdminAccountDtoFromJSON,
    AdminAccountDtoFromJSONTyped,
    AdminAccountDtoToJSON,
} from './AdminAccountDto';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    displayName: string;
    /**
     * 
     * @type {AbcAccountDto}
     * @memberof AccountDto
     */
    abcAccount?: AbcAccountDto;
    /**
     * 
     * @type {AdminAccountDto}
     * @memberof AccountDto
     */
    adminAccount?: AdminAccountDto;
}

/**
 * Check if a given object implements the AccountDto interface.
 */
export function instanceOfAccountDto(value: object): value is AccountDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    return true;
}

export function AccountDtoFromJSON(json: any): AccountDto {
    return AccountDtoFromJSONTyped(json, false);
}

export function AccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'abcAccount': json['abcAccount'] == null ? undefined : AbcAccountDtoFromJSON(json['abcAccount']),
        'adminAccount': json['adminAccount'] == null ? undefined : AdminAccountDtoFromJSON(json['adminAccount']),
    };
}

export function AccountDtoToJSON(value?: AccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'displayName': value['displayName'],
        'abcAccount': AbcAccountDtoToJSON(value['abcAccount']),
        'adminAccount': AdminAccountDtoToJSON(value['adminAccount']),
    };
}

