/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AbcLoginRequest,
  AbcLoginResponse,
  AdminLoginRequest,
  AdminLoginResponse,
  ResetPasswordRequest,
  SendResetPasswordLinkRequest,
  UpdateAdminPasswordRequest,
} from '../models/index';
import {
    AbcLoginRequestFromJSON,
    AbcLoginRequestToJSON,
    AbcLoginResponseFromJSON,
    AbcLoginResponseToJSON,
    AdminLoginRequestFromJSON,
    AdminLoginRequestToJSON,
    AdminLoginResponseFromJSON,
    AdminLoginResponseToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    SendResetPasswordLinkRequestFromJSON,
    SendResetPasswordLinkRequestToJSON,
    UpdateAdminPasswordRequestFromJSON,
    UpdateAdminPasswordRequestToJSON,
} from '../models/index';

export interface AuthenticateAsAdminRequest {
    adminLoginRequest: AdminLoginRequest;
}

export interface AuthenticateAsUserRequest {
    abcLoginRequest: AbcLoginRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest: ResetPasswordRequest;
}

export interface SendResetPasswordLinkOperationRequest {
    sendResetPasswordLinkRequest: SendResetPasswordLinkRequest;
}

export interface UpdateAdminPasswordOperationRequest {
    updateAdminPasswordRequest: UpdateAdminPasswordRequest;
}

/**
 * 
 */
export class SecurityApi extends runtime.BaseAPI {

    /**
     */
    async authenticateAsAdminRaw(requestParameters: AuthenticateAsAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminLoginResponse>> {
        if (requestParameters['adminLoginRequest'] == null) {
            throw new runtime.RequiredError(
                'adminLoginRequest',
                'Required parameter "adminLoginRequest" was null or undefined when calling authenticateAsAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminLoginRequestToJSON(requestParameters['adminLoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminLoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async authenticateAsAdmin(adminLoginRequest: AdminLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminLoginResponse> {
        const response = await this.authenticateAsAdminRaw({ adminLoginRequest: adminLoginRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async authenticateAsUserRaw(requestParameters: AuthenticateAsUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AbcLoginResponse>> {
        if (requestParameters['abcLoginRequest'] == null) {
            throw new runtime.RequiredError(
                'abcLoginRequest',
                'Required parameter "abcLoginRequest" was null or undefined when calling authenticateAsUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AbcLoginRequestToJSON(requestParameters['abcLoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AbcLoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async authenticateAsUser(abcLoginRequest: AbcLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AbcLoginResponse> {
        const response = await this.authenticateAsUserRaw({ abcLoginRequest: abcLoginRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['resetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'resetPasswordRequest',
                'Required parameter "resetPasswordRequest" was null or undefined when calling resetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters['resetPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(resetPasswordRequest: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw({ resetPasswordRequest: resetPasswordRequest }, initOverrides);
    }

    /**
     */
    async sendResetPasswordLinkRaw(requestParameters: SendResetPasswordLinkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sendResetPasswordLinkRequest'] == null) {
            throw new runtime.RequiredError(
                'sendResetPasswordLinkRequest',
                'Required parameter "sendResetPasswordLinkRequest" was null or undefined when calling sendResetPasswordLink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/send-reset-password-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendResetPasswordLinkRequestToJSON(requestParameters['sendResetPasswordLinkRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendResetPasswordLink(sendResetPasswordLinkRequest: SendResetPasswordLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendResetPasswordLinkRaw({ sendResetPasswordLinkRequest: sendResetPasswordLinkRequest }, initOverrides);
    }

    /**
     */
    async updateAdminPasswordRaw(requestParameters: UpdateAdminPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateAdminPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'updateAdminPasswordRequest',
                'Required parameter "updateAdminPasswordRequest" was null or undefined when calling updateAdminPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/update-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAdminPasswordRequestToJSON(requestParameters['updateAdminPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAdminPassword(updateAdminPasswordRequest: UpdateAdminPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAdminPasswordRaw({ updateAdminPasswordRequest: updateAdminPasswordRequest }, initOverrides);
    }

}
