import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import grey from "@mui/material/colors/grey";
import { useAlertService } from "../lib/alerts";
import { useAuthenticationService } from "../lib/authentication";

const drawerWidth = 240;

export function DashboardPage() {
  const navigate = useNavigate();

  const authentication = useAuthenticationService();
  const alerts = useAlertService();

  const handleLogOut = function () {
    alerts.info("Vous êtes déconnecté(e).");

    authentication.setUser(undefined);
    navigate("/login");
  };

  if (!authentication.getUser()) {
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            TM36 Mon entreprise
          </Typography>
          <IconButton
            size="large"
            color="inherit"
            aria-label="Se déconnecter"
            title="Se déconnecter"
            onClick={handleLogOut}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/conversations">
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary="Conversations" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/utilisateurs">
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary="Comptes utilisateurs" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: grey.A200,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
