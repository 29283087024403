/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CallPhoneNumberAction } from './CallPhoneNumberAction';
import {
    instanceOfCallPhoneNumberAction,
    CallPhoneNumberActionFromJSON,
    CallPhoneNumberActionFromJSONTyped,
    CallPhoneNumberActionToJSON,
} from './CallPhoneNumberAction';
import type { OpenAbcPageAction } from './OpenAbcPageAction';
import {
    instanceOfOpenAbcPageAction,
    OpenAbcPageActionFromJSON,
    OpenAbcPageActionFromJSONTyped,
    OpenAbcPageActionToJSON,
} from './OpenAbcPageAction';
import type { OpenWebPageAction } from './OpenWebPageAction';
import {
    instanceOfOpenWebPageAction,
    OpenWebPageActionFromJSON,
    OpenWebPageActionFromJSONTyped,
    OpenWebPageActionToJSON,
} from './OpenWebPageAction';

/**
 * @type WidgetAction
 * 
 * @export
 */
export type WidgetAction = { type: 'CallPhoneNumberAction' } & CallPhoneNumberAction | { type: 'OpenAbcPageAction' } & OpenAbcPageAction | { type: 'OpenWebPageAction' } & OpenWebPageAction;

export function WidgetActionFromJSON(json: any): WidgetAction {
    return WidgetActionFromJSONTyped(json, false);
}

export function WidgetActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetAction {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'CallPhoneNumberAction':
            return Object.assign({}, CallPhoneNumberActionFromJSONTyped(json, true), { type: 'CallPhoneNumberAction' } as const);
        case 'OpenAbcPageAction':
            return Object.assign({}, OpenAbcPageActionFromJSONTyped(json, true), { type: 'OpenAbcPageAction' } as const);
        case 'OpenWebPageAction':
            return Object.assign({}, OpenWebPageActionFromJSONTyped(json, true), { type: 'OpenWebPageAction' } as const);
        default:
            throw new Error(`No variant of WidgetAction exists with 'type=${json['type']}'`);
    }
}

export function WidgetActionToJSON(value?: WidgetAction | null): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'CallPhoneNumberAction':
            return CallPhoneNumberActionToJSON(value);
        case 'OpenAbcPageAction':
            return OpenAbcPageActionToJSON(value);
        case 'OpenWebPageAction':
            return OpenWebPageActionToJSON(value);
        default:
            throw new Error(`No variant of WidgetAction exists with 'type=${value['type']}'`);
    }

}

