/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';

/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    title?: string;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Section
     */
    groups: Array<Group>;
}

/**
 * Check if a given object implements the Section interface.
 */
export function instanceOfSection(value: object): value is Section {
    if (!('groups' in value) || value['groups'] === undefined) return false;
    return true;
}

export function SectionFromJSON(json: any): Section {
    return SectionFromJSONTyped(json, false);
}

export function SectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Section {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'groups': ((json['groups'] as Array<any>).map(GroupFromJSON)),
    };
}

export function SectionToJSON(value?: Section | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'groups': ((value['groups'] as Array<any>).map(GroupToJSON)),
    };
}

