import { Chip, ChipProps } from "@mui/material";

import AbcIcon from "@mui/icons-material/Abc";

export interface AbcChipProps {
  username?: string;
  ChipProps?: ChipProps;
}

export default function AbcChip(props: AbcChipProps): JSX.Element | null {
  const { username, ChipProps } = props;
  if (!username) {
    return null;
  }

  return <Chip icon={<AbcIcon />} label={username} {...ChipProps} />;
}
