/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetConversationResponse,
  GetConversationsResponse,
  SendMessageRequest,
  SendMessageResponse,
  SendMessageToGroupRequest,
} from '../models/index';
import {
    GetConversationResponseFromJSON,
    GetConversationResponseToJSON,
    GetConversationsResponseFromJSON,
    GetConversationsResponseToJSON,
    SendMessageRequestFromJSON,
    SendMessageRequestToJSON,
    SendMessageResponseFromJSON,
    SendMessageResponseToJSON,
    SendMessageToGroupRequestFromJSON,
    SendMessageToGroupRequestToJSON,
} from '../models/index';

export interface GetConversationRequest {
    id: number;
    since?: Date;
}

export interface GetConversationsRequest {
    since?: Date;
}

export interface MarkConversationAsReadByAdminRequest {
    id: number;
}

export interface PostMessageIntoConversationRequest {
    id: number;
    sendMessageRequest: SendMessageRequest;
}

export interface PostMessageToGroupRequest {
    sendMessageToGroupRequest: SendMessageToGroupRequest;
}

/**
 * 
 */
export class ConversationsApi extends runtime.BaseAPI {

    /**
     */
    async getConversationRaw(requestParameters: GetConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetConversationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getConversation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['since'] != null) {
            queryParameters['since'] = (requestParameters['since'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConversationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getConversation(id: number, since?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetConversationResponse> {
        const response = await this.getConversationRaw({ id: id, since: since }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getConversationsRaw(requestParameters: GetConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetConversationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['since'] != null) {
            queryParameters['since'] = (requestParameters['since'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConversationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getConversations(since?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetConversationsResponse> {
        const response = await this.getConversationsRaw({ since: since }, initOverrides);
        return await response.value();
    }

    /**
     */
    async markConversationAsReadByAdminRaw(requestParameters: MarkConversationAsReadByAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling markConversationAsReadByAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversations/{id}/acknowledge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markConversationAsReadByAdmin(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markConversationAsReadByAdminRaw({ id: id }, initOverrides);
    }

    /**
     */
    async postMessageIntoConversationRaw(requestParameters: PostMessageIntoConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendMessageResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling postMessageIntoConversation().'
            );
        }

        if (requestParameters['sendMessageRequest'] == null) {
            throw new runtime.RequiredError(
                'sendMessageRequest',
                'Required parameter "sendMessageRequest" was null or undefined when calling postMessageIntoConversation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendMessageRequestToJSON(requestParameters['sendMessageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async postMessageIntoConversation(id: number, sendMessageRequest: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendMessageResponse> {
        const response = await this.postMessageIntoConversationRaw({ id: id, sendMessageRequest: sendMessageRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async postMessageToGroupRaw(requestParameters: PostMessageToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sendMessageToGroupRequest'] == null) {
            throw new runtime.RequiredError(
                'sendMessageToGroupRequest',
                'Required parameter "sendMessageToGroupRequest" was null or undefined when calling postMessageToGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendMessageToGroupRequestToJSON(requestParameters['sendMessageToGroupRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postMessageToGroup(sendMessageToGroupRequest: SendMessageToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postMessageToGroupRaw({ sendMessageToGroupRequest: sendMessageToGroupRequest }, initOverrides);
    }

}
