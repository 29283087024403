/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAdminAccountRequest
 */
export interface CreateAdminAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminAccountRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminAccountRequest
     */
    displayName: string;
}

/**
 * Check if a given object implements the CreateAdminAccountRequest interface.
 */
export function instanceOfCreateAdminAccountRequest(value: object): value is CreateAdminAccountRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    return true;
}

export function CreateAdminAccountRequestFromJSON(json: any): CreateAdminAccountRequest {
    return CreateAdminAccountRequestFromJSONTyped(json, false);
}

export function CreateAdminAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAdminAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'displayName': json['displayName'],
    };
}

export function CreateAdminAccountRequestToJSON(value?: CreateAdminAccountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'displayName': value['displayName'],
    };
}

