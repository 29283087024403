/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MessageDto } from './MessageDto';
import {
    MessageDtoFromJSON,
    MessageDtoFromJSONTyped,
    MessageDtoToJSON,
} from './MessageDto';

/**
 * 
 * @export
 * @interface SendMessageResponse
 */
export interface SendMessageResponse {
    /**
     * 
     * @type {MessageDto}
     * @memberof SendMessageResponse
     */
    message: MessageDto;
}

/**
 * Check if a given object implements the SendMessageResponse interface.
 */
export function instanceOfSendMessageResponse(value: object): value is SendMessageResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function SendMessageResponseFromJSON(json: any): SendMessageResponse {
    return SendMessageResponseFromJSONTyped(json, false);
}

export function SendMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendMessageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageDtoFromJSON(json['message']),
    };
}

export function SendMessageResponseToJSON(value?: SendMessageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': MessageDtoToJSON(value['message']),
    };
}

