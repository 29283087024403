import { format, isToday, isYesterday } from "date-fns";
import { fr } from "date-fns/locale";

export interface FriendlyDateTimeProps {
  date: Date;
}

export default function FriendlyDateTime(props: FriendlyDateTimeProps) {
  const { date } = props;
  const full = format(date, "EEEE do MMMM yyyy à HH:mm:ss", { locale: fr });

  if (isToday(date)) {
    return <span title={full}>{format(date, "HH:mm", { locale: fr })}</span>;
  }
  if (isYesterday(date)) {
    return (
      <span title={full}>Hier à {format(date, "HH:mm", { locale: fr })}</span>
    );
  }

  return (
    <span title={full}>
      {format(date, "EEE d LLLL à HH:mm", { locale: fr })}
    </span>
  );
}
