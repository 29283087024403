import { useMemo } from "react";
import {
  AccountsApi,
  Configuration,
  ConversationsApi,
  HomeApi,
  MessagesApi,
  SecurityApi,
} from "../api-client";
import { useAuthenticationService } from "./authentication";

type ApiConstructor<T> = {
  new (configuration: Configuration): T;
};

function createUseApi<T>(ctor: ApiConstructor<T>) {
  return () => {
    const user = useAuthenticationService().getUser();
    const api = useMemo(() => {
      const configuration = new Configuration({
        basePath: process.env.REACT_APP_API_BASE_URL,
        accessToken: user?.accessToken,
      });
      return new ctor(configuration);
    }, [user?.accessToken]);

    return api;
  };
}

export const useAccountsApi = createUseApi(AccountsApi);
export const useConversationsApi = createUseApi(ConversationsApi);
export const useHomeApi = createUseApi(HomeApi);
export const useMessagesApi = createUseApi(MessagesApi);
export const useSecurityApi = createUseApi(SecurityApi);
