/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversationAccountDto
 */
export interface ConversationAccountDto {
    /**
     * 
     * @type {number}
     * @memberof ConversationAccountDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccountDto
     */
    displayName: string;
}

/**
 * Check if a given object implements the ConversationAccountDto interface.
 */
export function instanceOfConversationAccountDto(value: object): value is ConversationAccountDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    return true;
}

export function ConversationAccountDtoFromJSON(json: any): ConversationAccountDto {
    return ConversationAccountDtoFromJSONTyped(json, false);
}

export function ConversationAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationAccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
    };
}

export function ConversationAccountDtoToJSON(value?: ConversationAccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'displayName': value['displayName'],
    };
}

