/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbcAccountDto
 */
export interface AbcAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AbcAccountDto
     */
    username: string;
}

/**
 * Check if a given object implements the AbcAccountDto interface.
 */
export function instanceOfAbcAccountDto(value: object): value is AbcAccountDto {
    if (!('username' in value) || value['username'] === undefined) return false;
    return true;
}

export function AbcAccountDtoFromJSON(json: any): AbcAccountDto {
    return AbcAccountDtoFromJSONTyped(json, false);
}

export function AbcAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbcAccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
    };
}

export function AbcAccountDtoToJSON(value?: AbcAccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
    };
}

