/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AbcCredentials } from './AbcCredentials';
import {
    AbcCredentialsFromJSON,
    AbcCredentialsFromJSONTyped,
    AbcCredentialsToJSON,
} from './AbcCredentials';

/**
 * 
 * @export
 * @interface ThirdPartyCredentials
 */
export interface ThirdPartyCredentials {
    /**
     * 
     * @type {AbcCredentials}
     * @memberof ThirdPartyCredentials
     */
    abc: AbcCredentials;
}

/**
 * Check if a given object implements the ThirdPartyCredentials interface.
 */
export function instanceOfThirdPartyCredentials(value: object): value is ThirdPartyCredentials {
    if (!('abc' in value) || value['abc'] === undefined) return false;
    return true;
}

export function ThirdPartyCredentialsFromJSON(json: any): ThirdPartyCredentials {
    return ThirdPartyCredentialsFromJSONTyped(json, false);
}

export function ThirdPartyCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThirdPartyCredentials {
    if (json == null) {
        return json;
    }
    return {
        
        'abc': AbcCredentialsFromJSON(json['abc']),
    };
}

export function ThirdPartyCredentialsToJSON(value?: ThirdPartyCredentials | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'abc': AbcCredentialsToJSON(value['abc']),
    };
}

