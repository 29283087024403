/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MessageDto } from './MessageDto';
import {
    MessageDtoFromJSON,
    MessageDtoFromJSONTyped,
    MessageDtoToJSON,
} from './MessageDto';
import type { ConversationAccountDto } from './ConversationAccountDto';
import {
    ConversationAccountDtoFromJSON,
    ConversationAccountDtoFromJSONTyped,
    ConversationAccountDtoToJSON,
} from './ConversationAccountDto';

/**
 * 
 * @export
 * @interface ConversationDto
 */
export interface ConversationDto {
    /**
     * 
     * @type {number}
     * @memberof ConversationDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ConversationDto
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConversationDto
     */
    updatedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConversationDto
     */
    deletedDate: Date;
    /**
     * 
     * @type {ConversationAccountDto}
     * @memberof ConversationDto
     */
    owner: ConversationAccountDto;
    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof ConversationDto
     */
    messages: Array<MessageDto>;
    /**
     * 
     * @type {number}
     * @memberof ConversationDto
     */
    numberOfMessagesInTotal: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationDto
     */
    numberOfOwnerUnreadMessages: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationDto
     */
    numberOfAdminUnreadMessages: number;
    /**
     * 
     * @type {Date}
     * @memberof ConversationDto
     */
    lastMessageDate: Date;
}

/**
 * Check if a given object implements the ConversationDto interface.
 */
export function instanceOfConversationDto(value: object): value is ConversationDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    if (!('deletedDate' in value) || value['deletedDate'] === undefined) return false;
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('messages' in value) || value['messages'] === undefined) return false;
    if (!('numberOfMessagesInTotal' in value) || value['numberOfMessagesInTotal'] === undefined) return false;
    if (!('numberOfOwnerUnreadMessages' in value) || value['numberOfOwnerUnreadMessages'] === undefined) return false;
    if (!('numberOfAdminUnreadMessages' in value) || value['numberOfAdminUnreadMessages'] === undefined) return false;
    if (!('lastMessageDate' in value) || value['lastMessageDate'] === undefined) return false;
    return true;
}

export function ConversationDtoFromJSON(json: any): ConversationDto {
    return ConversationDtoFromJSONTyped(json, false);
}

export function ConversationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
        'deletedDate': (new Date(json['deletedDate'])),
        'owner': ConversationAccountDtoFromJSON(json['owner']),
        'messages': ((json['messages'] as Array<any>).map(MessageDtoFromJSON)),
        'numberOfMessagesInTotal': json['numberOfMessagesInTotal'],
        'numberOfOwnerUnreadMessages': json['numberOfOwnerUnreadMessages'],
        'numberOfAdminUnreadMessages': json['numberOfAdminUnreadMessages'],
        'lastMessageDate': (new Date(json['lastMessageDate'])),
    };
}

export function ConversationDtoToJSON(value?: ConversationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
        'deletedDate': ((value['deletedDate']).toISOString()),
        'owner': ConversationAccountDtoToJSON(value['owner']),
        'messages': ((value['messages'] as Array<any>).map(MessageDtoToJSON)),
        'numberOfMessagesInTotal': value['numberOfMessagesInTotal'],
        'numberOfOwnerUnreadMessages': value['numberOfOwnerUnreadMessages'],
        'numberOfAdminUnreadMessages': value['numberOfAdminUnreadMessages'],
        'lastMessageDate': ((value['lastMessageDate']).toISOString()),
    };
}

