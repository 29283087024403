import { createBrowserRouter } from "react-router-dom";
import {
  ConversationHelpFrame,
  ConversationsPage,
  ConversationThreadFrame,
  GroupMessageFrame,
} from "./router/conversations/ConversationsPage";
import { DashboardPage } from "./router/DashboardPage";
import { HelloPage } from "./router/hello/HelloPage";
import { LoginPage } from "./router/login/LoginPage";
import { UserAccountsPage } from "./router/users/UserAccountsPage";
import { ResetPasswordPage } from "./router/login/ResetPasswordPage";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/nouveau-mot-de-passe",
    element: <ResetPasswordPage />,
  },
  {
    path: "/",
    element: <DashboardPage />,
    children: [
      {
        path: "",
        element: <HelloPage />,
      },
      {
        path: "bonjour",
        element: <HelloPage />,
      },
      {
        path: "conversations",
        element: <ConversationsPage />,
        children: [
          {
            path: "",
            element: <ConversationHelpFrame />,
          },
          {
            path: ":conversationId",
            element: <ConversationThreadFrame />,
          },
          {
            path: "nouveau-message",
            element: <GroupMessageFrame />,
          },
        ],
      },
      {
        path: "utilisateurs",
        element: <UserAccountsPage />,
      },
    ],
  },
]);
